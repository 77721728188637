<template>
  <div
    v-loading="loading"
    class="bordered"
  >
    <el-row :gutter="20">
      <el-col
        :xs="12"
        :md="6"
      >
        <div class="form-control">
          <label>
            Audience Name
          </label>
          <el-input
            v-model="name"
          />
        </div>
        <div class="form-control">
          <label>
            Organization
          </label>
          <org-picker :organization.sync="organization" />
        </div>
        <div class="form-control">
          <label>Date Range</label>
          <date-picker
            :date-range.sync="dateRange"
            :organization="organization"
          />
        </div>
        <div class="form-control">
          <label>Radius</label>
          <el-select v-model="radius">
            <el-option
              v-for="(item, index) of radiusOptions"
              :key="index"
              :value="index"
              :label="(radiusFeet ? item.feet + ' feet' : item.meters + ' meters')"
            />
          </el-select>
          <el-switch
            v-model="radiusFeet"
            active-text="Feet"
            inactive-text="Meters"
            inactive-color="#0072cf"
          />
        </div>
        <div class="form-control">
          <label>
            Tags
          </label>
          <tag-picker :tags.sync="tags" />
        </div>
        <div>
          <el-button
            :disabled="cannotUpload"
            type="primary"
            @click="handlePlaceRequest"
          >
            Place Request
          </el-button>
        </div>
      </el-col>
      <el-col
        style="position: relative"
        :xs="12"
        :md="6"
      >
        <uploader
          ref="uploader"
          :accept-file-exts="fileExts"
          api-endpoint="/api/v1/latlngaudience"
          single-file
          upload-field-name="imports"
          @complete="uploadComplete"
          @file-change="handleFileChange"
          @upload-state-change="handleStateChange"
        />
      </el-col>
      <el-col
        :xs="24"
        :md="12"
        class="help-text"
      >
        <p>Please provide a .csv file in the following format:</p>
        <p><pre>location name,latitude,longitude</pre></p>
        <p><strong>Guidelines:</strong> A valid organization must be selected. Depending on the program being used to enter orders and update the file, it may be necessary to export the file to .csv format rather than just saving it as .csv.</p>
        <p>
          Please download <a :href="latLngTemplateUri">the CSV template</a>
          for an example.
        </p>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { Notification } from 'element-ui';
import { mapGetters } from 'vuex';
import { audience } from '@/adonis-api';
import { getSignedS3Url } from '../../methods/aws';
import { dateToString, isUTF8 } from '../../helpers';
// import { getValidationErrors } from '@/helpers/validation-rules';
import downloadFile from '@/helpers/download-file';
import DatePicker from '../global/DatePicker.vue';
import OrgPicker from '../global/OrgPicker';
import Uploader from '../global/Uploader.vue';
import TagPicker from '../global/TagPicker';
import papa from 'papaparse';
import booleanPointInPolygon from '@turf/boolean-point-in-polygon';

const ALLOWED_AREA_DESCRIPTION = 'the United States, including Alaska and Hawaii, or are lacking a location name';
const LATLNG_LIMIT = 10000;

const HAWAII = {
  type: 'Feature',
  properties: {},
  geometry: {
    type: 'Polygon',
    coordinates: [
      [
        [
          -160.422363,
          18.771115,
        ],
        [
          -160.422363,
          22.370396,
        ],
        [
          -154.709473,
          22.370396,
        ],
        [
          -154.709473,
          18.771115,
        ],
        [
          -160.422363,
          18.771115,
        ],
      ],
    ],
  },
};

const NORTH_AMERICA = {
  type: 'Feature',
  properties: {},
  geometry: {
    type: 'Polygon',
    coordinates: [
      [
        [
          -179.9999,
          24.544,
        ],
        [
          -51.9513812,
          24.544,
        ],
        [
          -51.9513812,
          83.3457868,
        ],
        [
          -179.9999,
          83.3457868,
        ],
        [
          -179.9999,
          24.544,
        ],
      ],
    ],
  },
};

export default {
  components: {
    DatePicker,
    OrgPicker,
    TagPicker,
    Uploader,
  },

  data() {
    return {
      loading: false,
      dateRange: [],
      errors: [],
      fileList: [],
      fileExts: ['.csv'],
      locations: [],
      badLocations: [],
      name: '',
      organization: this.$store.state.user.orgDetails,
      radius: 1,
      radiusFeet: true,
      radiusOptions: [
        { feet: 90, meters: 30 },
        { feet: 165, meters: 50 },
        { feet: 245, meters: 75 },
        { feet: 325, meters: 100 },
        { feet: 650, meters: 200 },
      ],
      tags: [],
    };
  },

  computed: {
    ...mapGetters('settings', [
      'latLngTemplateUri',
    ]),
    cannotUpload() {
      return this.name === '' || this.fileList.length === 0 || this.dateRange.length !== 2;
    },
  },

  methods: {
    downloadBadLocations(button) {
      const badLocations = ['"location name",latitude,longitude'];
      this.badLocations.map(row => badLocations.push(`"${row['location name']}",${row.latitude},${row.longitude}`));

      downloadFile(badLocations.join('\n'), {
        filename: `Bad-Locations-${new Date().toISOString().slice(0, 10)}.csv`,
        mimeType: 'text/csv',
        prettify: false,
      });
    },

    downloadTemplate() {
      getSignedS3Url
        .callPromise({
          uri: this.$store.state.settings.latLngTemplateUri,
        })
        .then(url => {
          window.location.href = url;
        })
        .catch(err => this.$reportError(err));
    },

    async handleFileChange(fileList) {
      this.loading = true;
      const utf8 = await isUTF8(fileList[0]);
      if (!utf8) {
        this.$refs.uploader.reset();
        this.$notify.error({
          message: 'File is not UTF-8 encoded.',
        });
        this.loading = false;
        return;
      }

      const reader = new FileReader();
      reader.onload = (e) => {
        const body = reader.result;
        const firstLine = body.split('\n').shift();
        const header = firstLine.replace('\r', '').replace(/"/g, '').split(',');
        if (['location name', 'latitude', 'longitude'].every(h => header.includes(h))) {
          this.fileList = fileList;
        } else {
          this.$refs.uploader.reset();
          this.$notify.error({
            message: 'File does not match required format. Please ensure you are using the latest template.',
          });
        }
      };
      papa.parse(fileList[0], {
        header: true,
        skipEmptyLines: true,
        dynamicTyping: true,
        complete: async (results, file) => {
          if (results.data.length > LATLNG_LIMIT) {
            this.$notify.error({
              message: `This file is too large, please limit the number of addresses to ${LATLNG_LIMIT.toLocaleString()} or fewer.`,
            });
            this.$refs.uploader.reset();
            this.fileList = [];
            return;
          }
          // Fail if any points are outside bounding boxes
          results.data.map(row => {
            if (
              row['location name'] &&
              (booleanPointInPolygon([row.longitude, row.latitude], NORTH_AMERICA) ||
              booleanPointInPolygon([row.longitude, row.latitude], HAWAII))
            ) {
              this.locations.push(row);
            } else {
              this.badLocations.push(row);
            }
          });
          if (this.badLocations.length > 0) {
            this.$notify.warning({
              message: this.$createElement(
                'div',
                null,
                [
                  `${this.badLocations.length.toLocaleString()} of ${(this.locations.length + this.badLocations.length).toLocaleString()} locations are not within ${ALLOWED_AREA_DESCRIPTION} and will not be imported.`,
                  this.$createElement(
                    'el-button',
                    {
                      style: {
                        'margin-top': '1em',
                      },
                      props: {
                        type: 'warning',
                        size: 'mini',
                      },
                      on: {
                        click: () => {
                          this.downloadBadLocations();
                          Notification.closeAll();
                        },
                      },
                    },
                    'Download Bad Locations',
                  ),
                ],
              ),
              duration: 20000,
            });
          }
          this.loading = false;
        },
      });
      reader.readAsText(fileList[0], 'UTF-8');
    },

    handleStateChange(isUploading) {
      this.loading = isUploading;
    },

    async handlePlaceRequest() {
      this.loading = true;

      const params = {
        name: this.name,
        type: 'LAT_LONG',
        start_date: dateToString(this.dateRange[0]),
        end_date: dateToString(this.dateRange[1]),
        radius: this.radiusOptions[this.radius].meters,
        organization_id: this.organization.id,
        tags: this.tags,
        locations: this.locations,
      };

      try {
        const response = await audience.importLatLong(params);

        window.mixpanel.track('Lat/Long Address', {
          ID: response.data.id,
          Name: response.data.name,
          Organization: this.organization.name,
          Geoframes: response.data.geo_count,
          'Start Date': response.data.start_date,
          'End Date': response.data.end_date,
          Tags: this.tags,
        });
        this.$notify.success({
          message: 'Your audience has been saved.',
        });

        this.resetForm();
        this.$router.push('/audiences/library/list');
      } catch (e) {
        this.$reportError(e);
        this.$notify.error({
          message: e.error,
          duration: 7500,
        });
      } finally {
        this.loading = false;
      }
    },

    resetForm() {
      this.$refs.uploader.reset();
      this.loading = false;
      this.organization = this.$store.state.user.orgDetails;
      this.name = '';
      this.dateRange = [];
      this.radius = 1;
      this.tags = [];
      this.files = [];
      this.fileList = [];
    },

    uploadComplete(result) {
      if (!result.status === 'success') {
        return this.$notify.error({
          message: 'Your request was not able to be processed.',
        });
      }

      this.$store.dispatch('mqtt/addRawJob', result.data.job);

      this.$notify.success({
        message: `Processing ${result.data.autopolygon.input_count} addresses.`,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.form-control {
  margin: 14px 0;
  label {
    margin-bottom: 5px;
    font-size: 14px;
    color: #666;
    display: block;
  }
  :deep( .el-tag) {
    background: #ddd;
    color: #333;
    margin: 0 5px 5px 0;
  }
  :deep( .el-select) {
    width: 60%;
  }
  :deep( .el-switch) {
    margin-left: 1em;
  }
}
:deep( .el-upload) {
  width: 100% !important;
  .el-upload-dragger {
    width: 100% !important;
  }
}

.bordered {
  margin-top: 1rem;
  padding: 15px 20px;
  border: 1px solid #eee;
  background: #fafafa;
}

.help-text p {
  font-size: 0.85rem;
  pre {
    font-size: 1rem;
    background: #ddd;
    padding: 5px;
    border: 1px solid #666;
  }
}
</style>
